<template>
  <div>
    <loading 
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false">
    </loading>
    <div v-for="error_message in errors" v-if="errors.length > 0">
      <CAlert color="danger" closeButton>{{error_message}}</CAlert>
    </div>
    <b-row>
      <b-col>
        <h3>KPI de ingresos y egresos</h3>
      </b-col>
    </b-row>
    
    <section>
      <b-row>
        <b-col lg="3">
          Fecha: {{date}}
          <b-form-group>
            <b-form-datepicker
              id="date"
              placeholder="dd/mm/yyyy"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              locale="es-ES"
              v-model="date"
              :class="{
                'form-control is-invalid': $v.date.$error,
                'form-control is-valid': !$v.date.$invalid
              }"
              v-bind="labels['es-ES'] || {}"
              today-button
              reset-button
            ></b-form-datepicker>
          </b-form-group>
        </b-col>

        <b-col lg="4">
          <b-form-group>
            <small>Ciudades</small>
            <v-select
              v-model="citySelected"
              :options="cities"
              placeholder="Selecciona tu ciudad"
              :class="{'form-control is-invalid': $v.citySelected.$error,
                              'form-control is-valid': !$v.citySelected.$invalid}"
            ></v-select>
            <b-form-invalid-feedback
              v-if="!$v.citySelected.required"
            >Debes seleccionar una ciudad</b-form-invalid-feedback>
          </b-form-group>
                           
        </b-col>

        <b-col lg="2">
          <br />
          <b-button
            @click="submit"
            variant="warning"
            :disabled="this.$v.$invalid"
          >
            Consultar
          </b-button>
        </b-col>
      </b-row>
    </section>

    <section>
      <b-row>
        <b-col sm="6" lg="2">              
          <CWidgetIcon
            :header="moneyAccumulated|thousand"
            text="Acumulado"
            color="gradient-success"
            >
            <CIcon name="cil-dollar" width="24"/>                
          </CWidgetIcon>            
        </b-col>
        <b-col sm="6" lg="2">              
          <CWidgetIcon
            :header="totalMoneyInPayments|thousand"
            text="Ingresos"
            color="gradient-success"
            >
            <CIcon name="cil-dollar" width="24"/>                
          </CWidgetIcon>            
        </b-col>
        <b-col sm="6" lg="2">              
          <CWidgetIcon
            :header="totalSpentMoney|thousand"
            text="Gastos"
            color="gradient-warning"
            >
            <CIcon name="cil-dollar" width="24"/>                
          </CWidgetIcon>            
        </b-col>
       
      </b-row>
      <b-row>
         <b-col sm="6" lg="2">              
          <CWidgetIcon
            :header="calculatedTotal|thousand"
            text="Total calculado"
            color="gradient-warning"
            >
            <CIcon name="cil-dollar" width="24"/>                
          </CWidgetIcon>            
        </b-col>
        <b-col sm="6" lg="2">              
          <CWidgetIcon
            :header="totalMoneyInAccounts|thousand"
            text="Cuentas bancarias"
            color="gradient-primary"
            >
            <CIcon name="cil-dollar" width="24"/>                
          </CWidgetIcon>            
        </b-col>
        <b-col sm="6" lg="2">              
          <CWidgetIcon
            :header="calculatedFormat.amount|thousand"
            :text="calculatedFormat.text"
            :color="calculatedFormat.color"
            >
            <CIcon name="cil-dollar" width="24"/>                
          </CWidgetIcon>            
        </b-col>
      </b-row>
    </section>

    <section>
      <b-row>
        <b-col lg="6">
          <CCard>
            <CCardHeader>
              Ingresos
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="6">
                  <CRow>
                   <!-- <CCol sm="6">
                      <CCallout color="info">
                        <small class="text-muted">En cuentas calculado</small><br>
                        <strong class="h4">{{calculatedTotal|thousand}}</strong>
                      </CCallout>
                    </CCol> -->
                     
                    <CCol sm="6">
                      <CCallout color="info">
                        <small class="text-muted">Ingresos cobrando</small><br>
                        <strong class="h4">{{(totalMoneyInPayments+pendingIncomes)|thousand}}</strong>
                      </CCallout>
                    </CCol>
                  </CRow>
                  <hr class="mt-0">
                  
                  
                  <ul class="horizontal-bars type-2">
                    <!-- <div class="progress-group mb-5">
                      <div class="progress-group-header">
                        <CIcon name="cil-user-female" class="progress-group-icon"/>
                        <span class="title">Ingresos recibidos</span>
                        <span class="ml-auto font-weight-bold">37%</span>
                      </div>
                      <div class="progress-group-bars">
                        <CProgress
                          class="progress-xs"
                          :value="37"
                          color="warning"
                        />
                      </div>
                    </div> -->
                                      
                    <div class="progress-group mb-5">
                      <div class="progress-group-header">
                        <CIcon name="cil-money" class="progress-group-icon"/>
                        <span class="title">Ingresos recibidos</span>
                        <span class="ml-auto font-weight-bold">
                          {{totalMoneyInPayments|thousand}}<span class="text-muted small">(100%)</span>
                        </span>
                      </div>
                      <div class="progress-group-bars">
                        <CProgress
                          class="progress-xs"
                          :value="100"
                          color="success"
                        />
                      </div>
                    </div>
                    
                  </ul>
                   <ul 
                    class="horizontal-bars type-2"
                    v-for="(serviceIncome, iIncome) in servicesIncomes"
                    v-bind:key="iIncome">
                    <div class="progress-group">
                      <div class="progress-group-header">
                        <CIcon name="cil-globe-alt" class="progress-group-icon"/>
                        <span class="title">{{serviceIncome.name}}</span>
                        <span class="ml-auto font-weight-bold">
                          {{serviceIncome.amount|thousand}}<span class="text-muted small">({{serviceIncome.percentage}}%)</span>
                        </span>
                      </div>
                      <div class="progress-group-bars">
                        <CProgress
                          class="progress-xs"
                          :value="Number(serviceIncome.percentage)"
                          color="success"
                        />
                      </div>
                    </div>                  
                  </ul>
                </CCol>

                <CCol sm="12" lg="6">
                  <CRow>
                    <CCol sm="6">
                      <CCallout color="warning">
                        <small class="text-muted">Ingresos pendientes</small><br>
                        <strong class="h4">{{pendingIncomes|thousand}}</strong>
                      </CCallout>
                    </CCol>
                    <!-- <CCol sm="6">
                      <CCallout color="success">
                        <small class="text-muted">Acumulado</small><br>
                        <strong class="h4">{{moneyAccumulated|thousand}}</strong>
                      </CCallout>
                    </CCol> -->
                  </CRow>
                  <hr class="mt-0">
                  <ul 
                    class="horizontal-bars type-2"
                    v-for="(serviceIncome, iIncome) in servicesDebts"
                    v-bind:key="iIncome">
                    <div class="progress-group">
                      <div class="progress-group-header">
                        <CIcon name="cil-globe-alt" class="progress-group-icon"/>
                        <span class="title">{{serviceIncome.name}}</span>
                        <span class="ml-auto font-weight-bold">
                          {{serviceIncome.amount|thousand}}<span class="text-muted small">({{serviceIncome.percentage}}%)</span>
                        </span>
                      </div>
                      <div class="progress-group-bars">
                        <CProgress
                          class="progress-xs"
                          :value="Number(serviceIncome.percentage)"
                          color="warning"
                        />
                      </div>
                    </div>                  
                  </ul>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>  
        </b-col>
       
   
       
        <b-col lg="6">
          <CCard>
            <CCardHeader>
              Dinero en cuentas
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="6">
                  <CRow>
                    <CCol sm="12">
                      <CCallout color="info">
                        <small class="text-muted">TOTAL MANUAL</small><br>
                        <strong class="h4">{{(totalMoneyInAccounts)|thousand}}</strong>
                      </CCallout>
                    </CCol>
                  </CRow>
                  <hr class="mt-0">
                  
                  <ul 
                    class="horizontal-bars type-2"
                    v-for="(serviceIncome, iIncome) in moneyInAccounts"
                    v-bind:key="iIncome">
                    <div class="progress-group">
                      <div class="progress-group-header">
                        <CIcon name="cil-globe-alt" class="progress-group-icon"/>
                        <span class="title">{{serviceIncome.account}}</span>
                        <span class="ml-auto font-weight-bold">
                          {{serviceIncome.amount|thousand}}<span class="text-muted small">({{serviceIncome.percentage}}%)</span>
                        </span>
                      </div>
                      <div class="progress-group-bars">
                        <CProgress
                          class="progress-xs"
                          :value="Number(serviceIncome.percentage)"
                          color="info"
                        />
                      </div>
                    </div>                  
                  </ul>
                </CCol>

                <CCol sm="12" lg="6">
                  <CRow>
                    <CCol sm="12">
                      <CCallout color="dark">
                        <small class="text-muted">TOTAL VIRTUAL</small><br>
                        <strong class="h4">{{(totalVirtualAccountsMoney)|thousand}}</strong>
                      </CCallout>
                    </CCol>
                  </CRow>
                  <hr class="mt-0">
                  
                  <ul 
                    class="horizontal-bars type-2"
                    v-for="(serviceIncome, iIncome) in virtualAccountsMoney"
                    v-bind:key="iIncome">
                    <div class="progress-group">
                      <div class="progress-group-header">
                        <CIcon name="cil-globe-alt" class="progress-group-icon"/>
                        <span class="title">{{serviceIncome.account}}</span>
                        <span class="ml-auto font-weight-bold">
                          {{serviceIncome.amount|thousand}}<span class="text-muted small">({{serviceIncome.percentage}}%)</span>
                        </span>
                      </div>
                      <div class="progress-group-bars">
                        <CProgress
                          class="progress-xs"
                          :value="Number(serviceIncome.percentage)"
                          color="info"
                        />
                      </div>
                    </div>                  
                  </ul>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>  
        </b-col>

        <b-col lg="6">
          <CCard>
            <CCardHeader>
              Gastos
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="12">
                  <CRow>
                    <CCol sm="12">
                      <CCallout color="danger">
                        <small class="text-muted">Total gastos</small><br>
                        <strong class="h4">{{totalSpentMoney|thousand}}</strong>
                      </CCallout>
                    </CCol>
                  </CRow>
                  <hr class="mt-0">
                  
                  <ul 
                    class="horizontal-bars type-2"
                    v-for="(serviceIncome, iIncome) in spendings"
                    v-bind:key="iIncome">
                    <div class="progress-group">
                      <div class="progress-group-header">
                        <CIcon name="cil-globe-alt" class="progress-group-icon"/>
                        <span class="title">{{serviceIncome.concept}}</span>
                        <span class="ml-auto font-weight-bold">
                          {{serviceIncome.amount|thousand}}<span class="text-muted small">({{serviceIncome.percentage}}%)</span>
                        </span>
                      </div>
                      <div class="progress-group-bars">
                        <CProgress
                          class="progress-xs"
                          :value="Number(serviceIncome.percentage)"
                          color="danger"
                        />
                      </div>
                    </div>                  
                  </ul>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>  
        </b-col>

      </b-row>      
    </section>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import Loading from 'vue-loading-overlay';
moment.locale("es");
const OWNER_ID = 1
const ADMIN_ID = 2;

export default {
  name: "MonthlyKPI",
  components: {Loading},
  data () {
    return {
      loading: true,
      errors: [],
      date: null,
      labels: {
        'es-ES': {            
          labelPrevYear: 'Año anterior',
          labelPrevMonth: 'Mes anterior',
          labelCurrentMonth: 'Mes actual',
          labelNextMonth: 'Siguiente mes',
          labelNextYear:'Siguiente año',
          labelTodayButton: 'Hoy',
          labelResetButton: 'Limpiar',
          labelHelp: 'Usa el cursor para navegar en las fechas'
        }
      },
      cities: [{value: 0, label: "Todas"}],
      citySelected: {value: 0, label: "Todas"},
      totalMoneyInAccounts: 0,
      totalMoneyInPayments: 0,
      moneyInAccounts: [],
      virtualAccountsMoney: [],
      moneyAccumulated: 0,
      totalSpentMoney: 0,
      spendings: [],
      pendingIncomes: 0,
      servicesIncomes: [],
      servicesDebts: [],
      version: 1,
    }
  },
  validations: {
    date: {
      required
    },
    citySelected: {
      required
    }
  },
  created() {
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    let access = this.userProfile.acceso.id
    this.date = moment(new Date()).format("YYYY-MM-")+'01';
    this.version = this.$route.query.version || 1;

    if (access ==  OWNER_ID || access == ADMIN_ID) {
      this.fetchCities();
    } else {
      this.flagErr = true
      this.errMsg = 'No posees los permisos para este módulo'
      this.loading = false
    }

  },
  computed: {
    calculatedTotal: {
      get() {
        return this.moneyAccumulated + this.totalMoneyInPayments 
          - this.totalSpentMoney
      }
    },
    calculatedFormat: {
      get() {
        let calculatedMoney = this.moneyAccumulated + this.totalMoneyInPayments - this.totalSpentMoney
        let totalDifference = calculatedMoney - this.totalMoneyInAccounts

        if (totalDifference === 0) {
          return {amount: 0, color: 'gradient-primary', text: 'AJUSTE CAJA OK'}
        } else if (totalDifference > 0){
          return {amount: totalDifference, olor: 'gradient-danger', text: 'Falta dinero en cuentas'}
        } else {
          return {amount: Math.abs(totalDifference), color: 'gradient-danger', text: 'Sobra dinero en cuentas'}
        }
      }
    },
  },
  methods: {
    
    fetchCities() {
      this.loading = true
      let getCities = this.$firebase.functions().httpsCallable('getCities')
      getCities().then((response) => {
        if(response.data != null) {
          let cities = response.data.data
          if(this.userProfile.acceso.id == OWNER_ID) {
            cities.map((city) => {            
              this.cities.push({
                value: city.id,
                label: city.nombre,
              })
            })
          } else if (this.userProfile.acceso.id == ADMIN_ID) {
            let cityFound = cities.find(city => city.nombre == this.userProfile.ciudad)            
            this.citySelected  = {
              value: cityFound.id,
              label: cityFound.nombre
            } 
            this.cities = [{
              value: cityFound.id,
              label: cityFound.nombre
            } ]

          }
        }        
      })
      .catch(error => console.log(error))
      .finally(_=>{
        this.loading = false
        this.submit() 
      })
    },
    submit() {
      this.loading = true
      const date = moment(this.date).format('YYYY-MM-')+'01T00:00:00.000Z'
      const lastDayOfMonth = moment(this.date)
          .endOf('month')
          .format('YYYY-MM-DD') + 'T23:59:59.290Z';
      let payload = {
        startDate: date,
        endDate: lastDayOfMonth,
        city: this.citySelected,       
      };
      console.log(payload)

      const fxName = this.version == 1 ? 'financialMonthlyKPI' : 'financialMonthlyKPIPermanent'

      let getFinancialKPIs = this.$firebase.functions().httpsCallable(fxName)

      getFinancialKPIs(payload).then(response => {
        this.loading = false
        console.log(response)
        let {
          totalMoneyInPayments,
          totalMoneyInAccounts,
          moneyInAccounts,
          virtualAccountsMoney,
          moneyAccumulated,
          totalSpentMoney,
          spendings,
          pendingIncomes,
          servicesIncomes,
          servicesDebts,
          totalVirtualAccountsMoney,
        } = response.data

        
        this.totalMoneyInAccounts = totalMoneyInAccounts
        this.totalMoneyInPayments = totalMoneyInPayments
        this.moneyInAccounts = moneyInAccounts
        this.virtualAccountsMoney = virtualAccountsMoney
        this.moneyAccumulated = moneyAccumulated
        this.totalSpentMoney = totalSpentMoney
        this.spendings = spendings
        this.pendingIncomes = pendingIncomes
        this.servicesIncomes = servicesIncomes
        this.servicesDebts = servicesDebts
        this.totalVirtualAccountsMoney = totalVirtualAccountsMoney

      }).catch(error => {
        console.log(error)
      })
    }
  }
};
</script>

<style lang="scss" scoped></style>
